import React, { ReactNode } from 'react'
import { Helmet } from 'react-helmet'
import { helmet } from '../../utils/helmet'
// import Header from '../header'
// import Container from '../container'
import * as s from './AppLayout.module.scss'

interface Props {
  children: ReactNode
}

const AppLayout = ({ children }: Props) => (
  <main className={s.layout}>
    <Helmet {...helmet} />
    {/* <Header /> */}
    {/* <Container> */}
    <div className={s.content}>{children}</div>
    {/* </Container> */}
  </main>
)

export default AppLayout
